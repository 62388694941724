<template>
<div class="relative w-full flex items-center pt-100px">
    <div class="absolute top-50px left-100px">
        <a class="text-white text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase" href="/">
            <blacklogo class="" />
        </a>
    </div>
    <div class="container flex flex-col">
        <div class="lg:w-full">
            <h1 class="font-medium text-2xl text-center">Company Info</h1>
        </div>
        <div class="bg-white lg:w-full md:6/12 w-10/12 my-auto mr-auto">
            <form @submit.prevent="submitForm" class="mt-6">

                <div class="flex w-full">
                    <div class="w-half pr-50px">
                        <div class="my-5 text-sm">
                            <label for="companyName" class="block text-black">Company Name</label>
                            <input type="text" v-model="form.companyName" id="company-name" class="rounded-sm px-4 py-3 mt-3 bg-gray-100 w-full" placeholder="Company Name" :class="{ 'border-1 border-red-500': $v.form.companyName.$error }" />
                            <div v-if="!$v.form.companyName.required && $v.form.companyName.$error" class="text-red-500">
                                The company name field is required
                            </div>
                            <div v-else-if="!$v.form.companyName.companyName && $v.form.companyName.$error" class="text-red-500">
                                The company name field is invalid
                            </div>
                        </div>
                    </div>
                    <div class="lg:w-half">
                        <div class="my-5 text-sm">
                            <label for="firstName" class="block text-black">First Name</label>
                            <input type="text" v-model="form.firstName" id="firstName" class="rounded-sm px-4 py-3 mt-3 bg-gray-100 w-full" placeholder="First Name" :class="{
                      'border-1 border-red-500': $v.form.firstName.$error,
                    }" />
                            <div v-if="$v.form.firstName.$error" class="text-red-500">
                                First name must be longer than 3 letters long.
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex w-full">
                    <div class="w-half pr-50px">
                        <div class="my-5 text-sm">
                            <label for="lastName" class="block text-black">Last Name</label>
                            <input type="text" v-model="form.lastName" id="lastName" class="rounded-sm px-4 py-3 mt-3 bg-gray-100 w-full border-gray" placeholder="Last Name" :class="{ 'border-1 border-red-500': $v.form.lastName.$error }" />
                            <div v-if="!$v.form.lastName.required && $v.form.lastName.$error" class="text-red-500">
                                The last name field is required
                            </div>
                            <div v-else-if="!$v.form.lastName.minLength" class="text-red-500">
                                The last name field should have 3 characters
                            </div>
                        </div>
                    </div>
                    <div class="lg:w-half">
                        <div class="my-5 text-sm">
                            <label for="contactRole" class="block text-black">Contact Role</label>
                            <input type="text" v-model="form.contactRole" id="secondary-password" class="rounded-sm px-4 py-3 mt-3 bg-gray-100 w-full" placeholder="Contact Role" :class="{
                      'border-1 border-red-500': $v.form.contactRole.$error,
                    }" />
                            <div v-if="$v.form.contactRole.$error" class="text-red-500">
                                The contact role field should have 3 chracters
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex w-full">
                    <div class="w-half pr-50px">
                        <div class="my-5 text-sm">
                            <label for="sector" class="block text-black">Sector</label>
                            <input type="text" v-model="form.sector" id="sector" class="rounded-sm px-4 py-3 mt-3 bg-gray-100 w-full border-gray" placeholder="Sector" :class="{ 'border-1 border-red-500': $v.form.sector.$error }" />
                            <div v-if="!$v.form.sector.required && $v.form.sector.$error" class="text-red-500">
                                The sector field is required
                            </div>
                        </div>
                    </div>
                </div>

                <!-- -->

                <div>
                    <!-- Loop through yearlyDetails -->
                    <div v-for="(yearlyDetail, index) in form.yearlyDetails" :key="index">

                        <div class="flex w-full">

                            <div class="w-half pr-50px">
                                <div class="my-5 text-sm">
                                    <label for="sector" class="block text-black">Year</label>
                                    <input type="text" v-model="yearlyDetail.year" id="sector" class="rounded-sm px-4 py-3 mt-3 bg-gray-100 w-full border-gray" placeholder="Year" />
                                    <div v-if="!$v.form.sector.required && $v.form.sector.$error" class="text-red-500">
                                        The sector field is required
                                    </div>
                                </div>
                            </div>

                            <div class="w-half">
                                <div class="my-5 text-sm">
                                    <label for="sector" class="block text-black">Gross Profit</label>
                                    <input type="text" v-model="yearlyDetail.grossProfit" id="sector" class="rounded-sm px-4 py-3 mt-3 bg-gray-100 w-full border-gray" placeholder="Gross Profit" />
                                    <div v-if="!$v.form.sector.required && $v.form.sector.$error" class="text-red-500">
                                        The sector field is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex w-full">
                            <div class="w-half pr-50px">
                                <div class="my-5 text-sm">
                                    <label for="sector" class="block text-black">Profit Before Tax</label>
                                    <input type="text" v-model="yearlyDetail.profitBeforeTax" id="sector" class="rounded-sm px-4 py-3 mt-3 bg-gray-100 w-full border-gray" placeholder="Revenue" />
                                    <div v-if="!$v.form.sector.required && $v.form.sector.$error" class="text-red-500">
                                        The sector field is required
                                    </div>
                                </div>
                            </div>

                            <div class="w-half">
                                <div class="my-5 text-sm">
                                    <label for="sector" class="block text-black">Non Current Assets</label>
                                    <input type="text" v-model="yearlyDetail.nonCurrentAssets" id="sector" class="rounded-sm px-4 py-3 mt-3 bg-gray-100 w-full border-gray" placeholder="Revenue" />
                                    <div v-if="!$v.form.sector.required && $v.form.sector.$error" class="text-red-500">
                                        The sector field is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex w-full">
                            <div class="w-half pr-50px">
                                <div class="my-5 text-sm">
                                    <label for="sector" class="block text-black">Current Assets</label>
                                    <input type="text" v-model="yearlyDetail.currentAssets" id="sector" class="rounded-sm px-4 py-3 mt-3 bg-gray-100 w-full border-gray" placeholder="Revenue" />
                                    <div v-if="!$v.form.sector.required && $v.form.sector.$error" class="text-red-500">
                                        The sector field is required
                                    </div>
                                </div>
                            </div>

                            <div class="w-half">
                                <div class="my-5 text-sm">
                                    <label for="sector" class="block text-black">Current Liabilities</label>
                                    <input type="text" v-model="yearlyDetail.currentLiabilities" id="sector" class="rounded-sm px-4 py-3 mt-3 bg-gray-100 w-full border-gray" placeholder="Revenue" />
                                    <div v-if="!$v.form.sector.required && $v.form.sector.$error" class="text-red-500">
                                        The sector field is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex w-full">
                            <div class="w-half pr-50px">
                                <div class="my-5 text-sm">
                                    <label for="sector" class="block text-black">Turnover</label>
                                    <input type="text" v-model="yearlyDetail.turnover" id="sector" class="rounded-sm px-4 py-3 mt-3 bg-gray-100 w-full border-gray" placeholder="Revenue" />
                                    <div v-if="!$v.form.sector.required && $v.form.sector.$error" class="text-red-500">
                                        The sector field is required
                                    </div>
                                </div>
                            </div>

                            <div class="w-half">
                                <div class="my-5 text-sm">
                                    <label for="sector" class="block text-black">Operating Profit</label>
                                    <input type="text" v-model="yearlyDetail.operatingProfit" id="sector" class="rounded-sm px-4 py-3 mt-3 bg-gray-100 w-full border-gray" placeholder="Revenue" />
                                    <div v-if="!$v.form.sector.required && $v.form.sector.$error" class="text-red-500">
                                        The sector field is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex w-full">
                            <div class="w-half pr-50px">
                                <div class="my-5 text-sm">
                                    <label for="sector" class="block text-black">Number of Employees</label>
                                    <input type="text" v-model="yearlyDetail.numberOfEmployees" id="sector" class="rounded-sm px-4 py-3 mt-3 bg-gray-100 w-full border-gray" placeholder="Revenue" />
                                    <div v-if="!$v.form.sector.required && $v.form.sector.$error" class="text-red-500">
                                        The sector field is required
                                    </div>
                                </div>

                            </div>

                            <div class="w-half">

                            </div>
                        </div>

                    </div>

                    <div class="flex items-center mb-40px">
                        <button class="flex items-center justify-center relative text-white p-3 duration-300 rounded-full lg:w-3/12 mr-50px px-20px bg-teal hover:bg-black" @click="addYearlyDetail">Add Yearly Detail</button>

                        <button class="flex items-center justify-center relative text-white p-3 duration-300 rounded-full lg:w-3/12 mr-50px px-20px bg-teal hover:bg-black" @click="removeYearlyDetail">Remove Yearly Detail</button>
                    </div>

                </div>

                <div v-if="errorMessage" class="mt-2 text-red-500">
                    {{ errorMessage }}
                </div>
                <button class="flex items-center justify-center relative text-white p-3 duration-300 rounded-full lg:w-3/12 mr-50px btn--arrow btn--arrow-white" :class="$v.form.$error ? 'bg-teal text-white-800 cursor-default' : 'bg-teal hover:bg-black'" :disabled="$v.form.$error">
                    <loading v-if="false" class="w-4 h-4 fill-current animate-spin duration-75" />
                    <span class="ml-4">Next Step</span>
                </button>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import firebase from "firebase";
import axios from "axios";
import store from "../../store";
import blacklogo from '@/assets/svg/blacklogo.svg';
import loading from '@/assets/svg/loading.svg';
import {
    required,
    email,
    sameAs,
    numeric,
    minLength
} from "vuelidate/lib/validators";

export default {
    name: "RegistrationForm",

    components: {
        loading,
        blacklogo
    },

    created() {
        firebase.auth().onAuthStateChanged(user => {
            store.dispatch("fetchUser", user);

            axios.defaults.headers = {
                'Content-Type': 'application/json',
                Authorization: user.za
            }

            let endpoints = [
                'https://pdai-api-7uc2rb5qma-nw.a.run.app/company/get',
            ];

            Promise.all(endpoints.map((endpoint) => axios.get(endpoint)))
                .then(([{
                    data: companies
                }]) => {
                    this.companies = companies;

                    // console.log(companies);
                });
        });
    },

    validations: {
        form: {
            companyName: {
                required,
                minLength: minLength(2),
            },

            firstName: {
                required,
                minLength: minLength(3),
            },

            lastName: {
                required,
                minLength: minLength(3),
            },

            contactRole: {
                required,
                minLength: minLength(3),
            },

            sector: {
                required,
                minLength: minLength(3),
            },
        },
    },

    data() {
        return {
            companies: '',
            form: {
                companyName: null,
                firstName: null,
                lastName: null,
                contactRole: null,
                sector: null,
                yearlyDetails: [{
                    year: null,
                    grossProfit: null,
                    profitBeforeTax: null,
                    nonCurrentAssets: null,
                    currentAssets: null,
                    currentLiabilities: null,
                    turnover: null,
                    operatingProfit: null,
                    numberOfEmployees: null
                }]
            },
            errorMessage: "",
            isLoading: false
        };
    },

    watch: {
        companies: function() {
            this.fillFormWithCompanies();
        }
    },

    methods: {

        fillFormWithCompanies() {
                this.form.companyName = this.companies.companyName;
                this.form.firstName = this.companies.contactFirstName;
                this.form.lastName = this.companies.contactLastName;
                this.form.contactRole = this.companies.contactRole;
                this.form.sector = this.companies.sector;
                this.form.yearlyDetails = this.companies.yearlyDetails;

                // if more than one yearly detail, populate the form with all the yearly details addYearlyDetail() if needed
                if (this.form.yearlyDetails.length > 1) {
                const diff = this.form.yearlyDetails.length - 1;
                
                for (let i = 0; i < diff; i++) {
                    this.addYearlyDetail();
                }
            }
        },
        
        addYearlyDetail() {
            const newYearlyDetail = {
                year: "",
                grossProfit: "",
                profitBeforeTax: "",
                nonCurrentAssets: "",
                currentAssets: "",
                currentLiabilities: "",
                turnover: "",
                operatingProfit: "",
            };
            this.form.yearlyDetails.push(newYearlyDetail);
            // this.$v.form.yearlyDetails.$reset(); // Reset validation for the new object
        },

        removeYearlyDetail() {
            if (this.form.yearlyDetails.length > 1) {
                this.form.yearlyDetails.pop();
            }
        },

        submitForm() {
            firebase.auth().onAuthStateChanged(user => {
                store.dispatch("fetchUser", user);

                axios.defaults.headers = {
                    'Content-Type': 'application/json',
                    Authorization: user.za
                }

                axios.post('https://pdai-api-7uc2rb5qma-nw.a.run.app/company/update', {
                    ...this.form,
                    // yearlyDetails: this.yearlyDetails
                })
            });
        }

    },
};
</script>
